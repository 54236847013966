import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Article from '../components/article'
import { StaticImage } from 'gatsby-plugin-image'

const Claim = () => {
  return (
    <>
      <Seo pageTitle={'Claim'} description={`How Do I Make A Claim?`} />
      <Layout>
        <main>
          <Article>
            <div className='card shadow border-primary mb-3 p-2'>
              <StaticImage
                className='card-img-top'
                src={'../images/emergency.png'}
                alt={'Combined Home Insurance'}
                placeholder='blurred'
                Layout={'fullWidth'}
                formats={['auto', 'webp', 'avif']}
                quality={100}
              ></StaticImage>
            </div>
            <h1 className='display-4'>How Do I Make A Claim?</h1>

            <div className='row justify-content-center my-5'>
              <div className='col-xl-8'>
                <div className='card shadow border-info'>
                  <div className='card-body'>
                    <div className='card-title'>
                      <h2>
                        For the following policies please call the claims
                        helpline on{' '}
                        <strong>
                          <a href='tel:03451223019'>0345 122 3019</a>
                        </strong>{' '}
                        to report the incident:
                      </h2>
                    </div>
                    <div className='card-text'>
                      <ul className='list-group list-group-flush'>
                        <li className='list-group-item'>
                          Home Insurance Policy
                        </li>
                        <li className='list-group-item'>
                          Landlords Insurance Policy
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h2 className='display-6'>Please make sure that you:</h2>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item'>
                Tell the police as soon as reasonably possible if something is
                lost or if you suspect theft or malicious damage.
              </li>
              <li className='list-group-item'>
                Take all practical steps to get back any property which has been
                lost.
              </li>
              <li className='list-group-item'>
                If you are asked to fill in a claim form please send this back
                with everything you are asked for within 30 days of the
                incident.
              </li>
              <li className='list-group-item'>
                Immediately send any written claim which is made against you,
                and give full details of any verbal claims made against you.
              </li>
              <li className='list-group-item'>
                Take reasonable action to protect the property from loss or
                damage.
              </li>
            </ul>

            <h2 className='display-6'>
              Do <strong>Not</strong>:
            </h2>
            <ul className='list-group list-group-flush'>
              <li className='list-group-item'>
                Throw away, get rid of or destroy items that are damaged until
                you are told to.
              </li>
              <li className='list-group-item'>
                Admit or deny responsibility for any incident; or Negotiate or
                settle any claims made against you by anyone else unless it is
                agreed in writing that you can.
              </li>
              <li className='list-group-item'>
                If you are insured for your buildings and your contents under
                the same policy your excess will be deducted from only one cover
                if you had to make a claim under both covers for the same
                incident.
              </li>
            </ul>

            <div className='row justify-content-center my-5'>
              <div className='col-xl-8'>
                <div className='card shadow border-info'>
                  <div className='card-body'>
                    <div className='card-title'>
                      <h2>
                        For Home Emergency claims please call{' '}
                        <strong>
                          <a href='tel:03451223019'>0345 257 1267</a>
                        </strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p>
              Before requesting emergency assistance, please check that the
              circumstances are covered by your policy. Please remember that
              this is not a maintenance policy and does not cover routine
              maintenance in your home.
            </p>
            <p>
              If your emergency is boiler related you should have your boiler
              make and model and service details ready when you contact the
              helpline. You must provide evidence to the approved engineer that
              the boiler has been serviced to the manufacturers specifications
              within the last twelve months for primary heating system cover to
              apply. Primary heating system cover must also be shown as included
              on your schedule.
            </p>
            <p>
              Telephone the helpline within 12 hours of the emergency occurring
              and provide details of the assistance you require.
            </p>
            <p>
              All requests for emergency assistance must be made through the
              helpline. Do not make arrangements yourself without prior
              authorisation from the helpline.
            </p>

            <h3 className='display-6'>Pay On Use</h3>
            <p>
              Should an emergency arise that is not included under your Home
              Emergency cover, where possible, the helpline can arrange for an
              approved engineer to attend your home but you will be responsible
              for all costs involved.
            </p>
          </Article>
        </main>
      </Layout>
    </>
  )
}

export default Claim
